import React from 'react';

import { init as initApm } from '@elastic/apm-rum';
import { OptimizelyProvider } from '@optimizely/react-sdk';
import { inspect } from '@xstate/inspect';
import AppContainer from 'AppContainer';
import ReactDOM from 'react-dom';

import { initialOptimizelyUser, optimizely } from 'services/optimizely';
import 'utils/polyfills';
import 'utils/sentry';

import * as serviceWorker from './serviceWorker';

// import mixpanel from './MixPanelTracking';

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_XSTATE_DEBUG) {
  inspect({ url: 'https://statecharts.io/inspect', iframe: false });
}

if (process.env.NODE_ENV === 'production') {
  initApm({
    environment: process.env.NODE_ENV,
    serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
    serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
    centralConfig: false,
    transactionSampleRate: 0.3,
  });
}

// const RUM_MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN || "placeholder__";

// mixpanel.init(RUM_MIXPANEL_TOKEN, {
//   debug: false,
//   persistence: "localStorage",
// });

ReactDOM.render(
  <OptimizelyProvider optimizely={optimizely} user={initialOptimizelyUser}>
    <AppContainer />
  </OptimizelyProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
