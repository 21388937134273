import { createInstance } from '@optimizely/react-sdk';

export enum FeatureKey {
  AccessMealPlannerFromUserProfile = 'bodyprophet_-_access_meal_planner_from_user_profile_ui',
  ClientManagementDashboard = 'bodyprophet_-_client_management_dashboard',
  DirectMessages = 'bodyprophet_-_direct_messages',
  FoodTagFlags = 'bodyprophet_-_food_tag_flags_ui',
  FoodTagFoodDislikeFlag = 'bodyprophet_-_food_tag_food_dislike_flag_ui',
  GoalSetterFoodDislikesScreen = 'bodyprophet_-_goal_setter_food_dislikes_screen',
  GoalSetterEatingHabitsScreen = 'bodyprophet_-_goal_setter_eating_habits_screen',
  GoalSetterSupplementsScreen = 'bodyprophet_-_goal_setter_supplements_screen',
  GoalSetterTargetsScreen = 'bodyprophet_-_goal_setter_track_targets_screen',
  GoalTargetsAdjustmentControl = 'bodyprophet_-_goal_targets_adjustment_control_ui',
  JuniorReviews = 'bodyprophet_-_junior_reviews_ui',
  NewParQConfigForGtTrial = 'bodyprophet_-_new_parq_config_for_gt_trial',
  NewCreateUserFormFlag = 'bodyprophet_-_new_user_create_form',
  ParqLiteLink = 'bodyprophet_-_parq_lite_link',
  ParqWidget = 'bodyprophet_-_parq_status_widget',
  ParqNew = 'bodyprophet_-_create_new_parq',
  PerformanceStandards = 'bodyprophet_-_performance_standards',
  ProgressOverview = 'bodyprophet_-_user_progress_overview_page',
  ProgressOverviewCheckInDate = 'bodyprophet_-_progress_overview_check_in_date',
  Recipes = 'bodyprophet_-_recipes_ui',
  RMRMealPlan = 'bodyprophet_-_rmr_meal_plan',
  MealPlannerUpdates = 'bodyprophet_-_meal_planner_updates',
  SessionStateManagment = 'bodyprophet_-_session_state_management',
  SSO = 'bodyprophet_-_up_single_sign_on_ui',
  StaffTalentMatrix = 'bodyprophet_-_staff_talent_matrix_ui',
  SupplementStacks = 'bodyprophet_-_supplement_stacks_ui',
  SupplementTags = 'bodyprophet_-_supplement_tags_ui',
  TrainerAssessment = 'bodyprophet_-_trainer_assessments_ui',
  TrackerStatsWidget = 'bodyprophet_-_tracker_tab_stats_widget_ui',
  LeavingDate = 'bodyprophet_-_leaving_date',
  RemoveFromRPNC = 'bodyprophet_remove_from_rpnc',
  ProfileStatusUpdates = 'bodyprophet_-_profile_status_updates',
  MobileMenu = 'bodyprophet_-_mobile_menu',
  ResultsMenuOption = 'bodyprophet_-_results',
  Submissions = 'bodyprophet_-_submissions',
  SubmissionCreate = 'bodyprophet_-_submission_create',
  SubmissionsGridView = 'bodyprophet_-_submissions_grid_view',
  SubmissionsTopResults = 'bodyprophet_-_submissions_-_top_result_page',
  SubmissionsGymFloorFootage = 'bodyprophet_-_submission_gym_floor_footage',
  ScoringQueue = 'bodyprophet_-_scoring_queue',
  DiscretionaryPoints = 'bodyprophet_-_discretionary_points',
  GoogleYelpReview = 'bodyprophet_-_google_yelp_review',
  FixUserButton = 'bodyprophet_-_fix_user_button',
  DiscretionaryPromotions = 'bodyprophet_-_discretionary_promotions',
}

export const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_SDK_KEY,
  // null disables logger, but library typings not support null yet
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logLevel: null as any,
});

export const initialOptimizelyUser = { id: 'initialUserId' };
